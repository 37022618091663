import {Container, Row, Col} from 'react-bootstrap';
import { MailchimpForm } from './MailchimpForm';
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className='align-items-center'>
                    <MailchimpForm />
                    <Col size={12} sm={6}>
                    </Col>
                    <Col szie={12} sm={6} className="text-center text-sm-end">
                        <div className='social-icon'>
                            <a href="https://www.linkedin.com/in/kundan-v-776621b4/"><img src={navIcon1} alt="Icon" /></a>
                            <a href="https://github.com/kk99351"><img src={navIcon2} alt="Icon" /></a>
                            <a href="https://www.instagram.com/kundan_v351/"><img src={navIcon3} alt="Icon" /></a>
                        </div>
                        <p>Copyright 2022. All Right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}