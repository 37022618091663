import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg4 from "../assets/img/sentimental.png";
import projImg5 from "../assets/img/clustering.png";
import projImg6 from "../assets/img/dimenion.png";
import projImg7 from "../assets/img/regression.png";
import projImg8 from "../assets/img/classification.png";
import projImg9 from "../assets/img/doom.png";
import projImg10 from "../assets/img/self_driving.png";
import projImg11 from "../assets/img/ai_flight.png";
import projImg12 from "../assets/img/camera_detection.png";
import projImg13 from "../assets/img/drowsy_detection.png";
import projImg14 from "../assets/img/amazona.png";
import projImg15 from "../assets/img/mantra.png";
import projImg16 from "../assets/img/tindog.png";
import projImg17 from "../assets/img/voyager.png";
import projImg18 from "../assets/img/dcoder.png";
import projImg19 from "../assets/img/moviedukaan.png";
import colorSharp2 from "../assets/img/color-sharp2.png"

export const Projects = () => {
    const projects = [
        {
            title: "NLP",
            description: "Sentimental Analysis",
            imgUrl: projImg4,
        },
        {
            title: "Clustering",
            description: "Clusterd group of Mall customers",
            imgUrl: projImg5,
        },
        {
            title: "Dimensionality Reduction",
            description: "Reduced Features of Wine Shop Dataset",
            imgUrl: projImg6,
        },
        {
            title: "Regression",
            description: "Model to find salaries of Employess based on Dataset Features",
            imgUrl: projImg7,
        },
        {
            title: "Classification",
            description: "Social Network Advertisment Classifer",
            imgUrl: projImg8,
        },
    ];

    const projects0 = [
        {
            title: "Deep Q Learning",
            description: "Doom",
            imgUrl: projImg9,
        },
        {
            title: "Deep Convulational Q Learning",
            description: "Self Driving Car",
            imgUrl: projImg10,
        },
        {
            title: "Reinforcement Learning",
            description: "Ai Flight : Unity Ml Agents",
            imgUrl: projImg11,
        },
        {
            title: "DLib",
            description: "Camera Based Attendence System",
            imgUrl: projImg12,
        },
        {
            title: "DLib and Random Forest Classification",
            description: "Drowsy Detection System",
            imgUrl: projImg13,
        },
    ];

    const projects1 = [
        {
            title: "Amazona",
            description: "Ecommerce on React/Redux",
            imgUrl: projImg14,
        },
        {
            title: "Mantra",
            description: "Logistics Web Portal",
            imgUrl: projImg15,
        },
        {
            title: "Tindog",
            description: "Dog Social Page (Static)",
            imgUrl: projImg16,
        },
        {
            title: "Voyager",
            description: "College Trip Registration",
            imgUrl: projImg17,
        },
        {
            title: "Dcoder",
            description: "Third Party Coding Platform",
            imgUrl: projImg18,
        },
        {
            title: "MovieDukaan",
            description: "Movies Collection",
            imgUrl: projImg19,
        },
    ];

    return (
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                    <h2>Projects</h2>
                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center" id="pills-tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Data Science</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Machine Learning</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Web Development</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Row>
                                {
                                    projects.map((project, index) => {
                                        return (
                                            <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                        <Row>
                                {
                                    projects0.map((project, index) => {
                                        return (
                                            <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                        <Row>
                                {
                                    projects1.map((project, index) => {
                                        return (
                                            <ProjectCard
                                                key={index}
                                                {...project}
                                                />
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                    </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}